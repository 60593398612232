#page-room {
    header{
        padding: 24px;
        border-bottom: 1px solid #e2e2e2;

        .content-header {
            max-width: 1128px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;
            flex-wrap: wrap;
            flex-direction: column;

            .actions{
                display: flex;
                @media(max-width: 620px) {
                    flex-direction: column;
                }
            }

            .compra-chance{
                display: flex;
                align-items: center;
                justify-content: flex-end;
                margin: 10px;
                cursor: pointer;

                span{
                    height: 50px;
                    background: #61CE70;
                    border-radius: 8px;
                    padding: 16px 16px;
                    color: #FFF;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            >img {
                max-height: 45px;
                margin: 10px;
                cursor: pointer;
            }

            > button {
                margin: 10px;
            }

            > div {
                display: flex;

                button {
                    height: 40px;
                    margin-left: 16px;
                }
            }
        }
    }
}