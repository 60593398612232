#page-auth{
    display: flex;
    align-items: stretch;
    height: 100vh;

    @media only screen and (max-width: 400px) {
    }

    aside{
        display: flex;
        flex:6;
        background: #FD0178;
        color: #fff;
        flex-direction: column;
        justify-content: center;
        padding: 128px 88px;
        img{
            max-width: 320px;
        }
        strong{
            font: 700 36px 'Poppins', sans-serif;
            line-height: 42px;
            margin-top: 16px;
        }

        p{
            font-size: 18px;
            line-height: 32px;
            margin-top: 16px;
            color:#f8f8f8;
        }
    }

    @media only screen and (max-width: 639px) {
        aside{
            display: none;
        }
    }

    main{
        flex:8;
        padding: 0 32px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .main-content{
        flex-direction: column;
        display: flex;
        width: 100%;
        max-width: 320px;
        align-items: stretch;
        text-align: center;
        > img{
            align-self: center;
            margin-bottom: 60px;
        }

        span {
            margin: 8px;
            font-size: 14px;
            color: #a8a8b3;
        }

        .msg-jurado{
            margin-top: 14px;

        }

        h2{
            font-size: 24px;
            margin: 64px 0 24px;
            font-family: 'Poppins', sans-serif;
        }

        form{
            input{
                height: 50px;
                border-radius: 8px;
                padding: 0 16px;
                margin: 10px 0;
                background: #fff;
                border: 1px solid #a8a8b3;
            }

            button{
                margin-top: 16px;
            }

            button, input {
                width: 100%;
            }

        }

        p{
            font-size: 14px;
            color: #737388;
            margin-top: 16px;
        }

        .hiddenlogin{
            display:none;
        }

        .showlogin{
            display:block;
        }

        .showsoujurado {
            button{
                margin-top: 16px;
            }

            button, input {
                width: 100%;
            }
        }
        .hiddensoujurado {
            display:none;
        }
    }

    .separator{
        font-size: 14px;
        color: #a8a8b3;
        margin: 20px 0;
        display: flex;
        align-items: center;

        &::before{
            content: '';
            flex: 1;
            height: 1px;
            background: #a8a8b3;
            margin-right: 16px;
        }

        &::after{
            content: '';
            flex: 1;
            height: 1px;
            background: #a8a8b3;
            margin-left: 16px;
        }
    }

    .create-room-google{
        margin-top: 8px;
        height: 50px;
        border-radius: 8px;
        font-weight: 500;
        background: #61CE70;
        color: #fff;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        border: 0;
        img{
            margin-right: 8px;
        }

        transition: filter 0.2s;

        &:hover{
            filter: brightness(0.9);
        }
    }
}