#page-room {
    header{
        padding: 24px 12px 24px 12px;
        border-bottom: 1px solid #e2e2e2;

        .content {
            max-width: 1128px;
            margin: 0 auto;
            display: flex;
            justify-content: space-between;
            align-items: center;

            >img {
                max-height: 45px;
            }

            > div {
                display: flex;

                button {
                    height: 40px;
                    margin-left: 16px;
                }
            }
        }
    }
    
    main {
        max-width: 800px;
        margin: 0 auto;

        .room-voto{
            margin: 10px 0 0 0;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            span{
                margin-left: 16px;
                background: #61CE70;
                border-radius: 9999px;
                padding: 8px 16px;
                color: #FFF;
                font-weight: 500;
                font-size: 14px;
            }
        }

        .room-title{
            margin: 32px 0 24px;
            display: flex;
            align-items: center;
            margin-left: 8px;

            h1{
                font-family: 'Pappins', sans-serif;
                font-size: 24px;
                color: #29292e;
            }

            

        }

        form{
            textarea{
                width: 100%;
                border: 0;
                padding: 16px;
                border-radius: 8px;
                background: #fefefe;
                box-shadow: 0 2px 12px rgba(0, 0, 0, 0.04);
                resize: vertical;
                min-height: 130px;
            }
        }
        .form-footer{
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-top: 16px;

            .user-info{
                display: flex;
                align-items: center;

                img{
                    width: 32px;
                    height: 32px;
                    border-radius: 50%;
                }

                span{
                    margin-left: 8px;
                    color: #29292e;
                    font-weight: 500;
                    font-size: 14px;
                }
            }

            .login-info{
                display: flex;
                align-items: center;

                span{
                    font-size: 14px;
                    color: #737388;
                    font-weight: 500;

                    button{
                        background: transparent;
                        border: 0;
                        color: #835AFD;
                        text-decoration: underline;
                        font-size: 14px;
                        font-weight: 500;
                        cursor: pointer;
                    }
                }
            }
        }

        .question-list{
            margin-top: 32px;

            .list-flex{
                display: flex;
                flex-direction: row;
                flex-wrap: wrap;
                justify-content: space-around;
                align-content: center;

            }
        }

    }
}